// Adjust these paths to your skin path if you want to recompile the SASS
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@media all {
  .app-swiper {
    border-bottom: 1px dashed #000000;
    padding-bottom: 10px;
    position: relative;

    &.swiper-fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;

      @media (max-width: 1025px) {
        height: 100vh;
        position: relative;
      }

      .swiper-pagination {
        bottom: 470px;
        height: auto;
        left: -10%;
        line-height: 5px;
        margin-left: 50%;
        overflow: hidden;
        position: absolute;
        text-align: center;
        width: 20%;
        z-index: 35;
        
        @media (max-width: 1025px) {
          bottom: 200px;
        }

        @media (max-width: 768px) {
          bottom: 20px; left: -25%; width: 50%;
        }

        &.black {
          .swiper-pagination-bullet {
            border-color: #000;
          }
          .swiper-pagination-bullet-active {
            background-color: #000 !important;
          }            
        }
        
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          background-color: transparent;
          border: 1px solid 1px solid #fff;
          opacity: 1;
          margin: 0 4px;
        }
      
        .swiper-pagination-bullet-active {
          background-color: #fff !important;
        }
      }
      
      .swiper-container {
        &.height-100vh {
          height: 100%;
        }

        &.black {
          .swiper-button-prev,
          .swiper-button-next {
            color: #000;
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #fff;

          @media (max-width: 1025px) {
            bottom: 180px;
            top: auto;
          }
  
          @media (max-width: 768px) {
            top: auto;
            bottom: 10px;
          }
        }        

        .swiper-slide {
          .co-slidertext {
            position: absolute;
            top: 23%;
            left: 0;
            z-index: 15;
            width: 100%;
            margin: 0;
            min-height: 0;
            max-height: none;

            @media (max-width: 750px) {
              top: 16%;
            }

            .content {
              font-size: 22px;
              width: 88%;
              max-width: 1140px;
              margin: 0 auto;
              padding: 0 6%;

              @media (max-width: 1025px) {
                padding-left: 2%; 
                padding-right: 2%;
                width: 96%;
              }

              @media (max-width: 768px) {
                padding-left: 3%;
                padding-right: 3%;
                width: 94%;
              }

              .heading, .copy {
                background: rgba(0,0,0,0.85);
                color: #FFFFFF;
                display: inline-block;
              }

              .heading {
                font-family: 'AGaramond-RegularSC', Times, serif;                
                padding: 4px 15px;
              }

              .copy {
                font-family: 'AGaramond-Regular', Times, serif;
                font-size: 1.36em;
                line-height: 29.7px;
                padding: 7px 15px;

                .arrow_big_right.pink {
                  display: inline-block;
                  margin: 0 0 0 10px;
                  vertical-align: calc(-3px);
                  transition: all 150ms ease-in-out;
                }
                
              }
            }
          }
          img {
              height: 100%;
              object-fit: cover;
            width: 100%;
            max-height: none;
          }
        }
      }
    }
    
    @media (min-width: 769px) {
      margin: 0 -2.6% 1.5em;
    }

    @media (max-width: 1025px) {
      margin: 0 -1.5% 1.5em;
    }

    @media (max-width: 768px) {
      margin: 0 -3% 1.5em;
    }

    .fancybox-link {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 11;

      .fa-stack {
        width: 24px;
        height: 24px;
        svg {
          font-size: 12px;
        }
      }
    }

    .aspect-ratio {      
      position: relative;
      
      &.height-16-9 {
        padding-top: 56.25%;
      }
  
      &.height-4-3 {
        padding-top: 75%;
      }
  
      &.height-3-2 {
        padding-top: 66.66%;
      }
  
      &.height-8-5 {
        padding-top: 62.5%;
      }
  
      &.height-2-1 {
        padding-top: 50%;
      }
  
      &.height-1-1 {
        padding-top: 100%;
      }
  
      &.height-16-9,
      &.height-4-3,
      &.height-3-2,
      &.height-8-5,
      &.height-2-1,
      &.height-1-1 {
        width: 100%;
  
        .inner {
          position:  absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
    
          .swiper-container {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  
    [style*="--aspect-ratio"] {
      .inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
  
        .swiper-container {
          width: 100% !important;
          height: 100% !important;
        } 
      }
    }  
  
    .swiper-container {
      width: 100%;
      height: 50vh;
  
      .swiper-slide {
        cursor: move;

        img {
          display: block;
          margin: auto;

          @media (max-width: 767px) {
            max-height: 234px;
          } 

          @media (min-width: 768px) and (max-width: 1023px) {
            max-height: 479px;
          }
      
          @media (min-width: 1024px) and (max-width: 1025px) {
            max-height: 656px;
          }
          
          max-height: 567px;
          
        }

        .image-wrapper {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
  
          &.dark-content {
            p {
              color: #000;
              text-shadow: 0px 0px 16px rgba(255, 255, 255, 0.8);
  
            }
  
            &.content-position-cc,
            &.content-position-cl,
            &.content-position-cr {
              .overlay-gradient {
                background: linear-gradient(0deg, rgba(255,255,255,0) 25%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 75%);
              }
  
              .overlay-gradient-wide {
                background: linear-gradient(0deg, rgba(255,255,255,0) 10%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 90%);
              }
  
              
            }
  
            &.content-position-bc,
            &.content-position-bl,
            &.content-position-br {
              .overlay-gradient {
                background: linear-gradient(0deg, rgba(255,255,255,0.4) 25%, rgba(255,255,255,0) 50%);
              }
  
              .overlay-gradient-wide {
                background: linear-gradient(0deg, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 100%);
              }
            }
  
            &.content-position-tc,
            &.content-position-tl,
            &.content-position-tr {
              .overlay-gradient {
                background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.4) 75%);
              }
  
              .overlay-gradient-wide {
                background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 50%);
              }
            }
          }
  
          .overlay-none {
            display: none;
          }
  
          &.content-effect-gradient,
          &.content-effect-gradient-wide {
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
            }
          }
  
          &.content-position-cc,
          &.content-position-cl,
          &.content-position-cr {
            .overlay-gradient {
              background: linear-gradient(0deg, rgba(255,255,255,0) 25%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 75%);
            }
  
            .overlay-gradient-wide {
              background: linear-gradient(0deg, rgba(255,255,255,0) 10%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 90%);
            }
  
            
          }
  
          &.content-position-bc,
          &.content-position-bl,
          &.content-position-br {
            .overlay-gradient {
              background: linear-gradient(0deg, rgba(0,0,0,0.4) 25%, rgba(255,255,255,0) 50%);
            }
  
            .overlay-gradient-wide {
              background: linear-gradient(0deg, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 100%);
            }
          }
  
          &.content-position-tc,
          &.content-position-tl,
          &.content-position-tr {
            .overlay-gradient {
              background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(0,0,0,0.4) 75%);
            }
  
            .overlay-gradient-wide {
              background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 50%);
            }
          }
        }

        .co-slidertext {
          min-height: 90px;
          max-height: 90px;
          display: block;
          font-family: 'Apercu-Regular', Helvetica, Arial, sans-serif;
          font-size: 0.636em;
          line-height: 1.6em;
          margin: 1em auto 0 13.5%;
          width: 69%;
        }
  
        // p {
        //   color: #fff;
        // 	font-size: $font-size-base*1.25;
        //   text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
        //   margin: 0 0 $font-size-base * 0.6875;
          
        //   &.lead { 
        //     margin-bottom: $grid-gutter-width/2;
        //     font-size: $font-size-base*1.8;	
        //     font-weight: 600;
  
        //     @include media-breakpoint-up(md)  {
        //       line-height: 1.2em;
        //       font-size: $font-size-base*3;	
        //     }		
        //     @include media-breakpoint-up(lg)  {
        //       line-height: 1.2em;
        //       font-size: $font-size-base*4;	
        //     }				
        //     @include media-breakpoint-up(xl)  {
        //       line-height: 1.2em;
        //       font-size: $font-size-base*5;	
        //     }				
        //   }
        // }	
      }
  
      .swiper-button-next,
      .swiper-button-prev {
        color: #000;
        outline: none;
  
        &:after {
          font-size: 30px;
        }
      }
    }
    .swiper-pagination {
      width: 100%;
      position: relative;
      margin-top: 10px;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid 1px solid #878787;
        opacity: 1;
        margin: 0 4px;
      }
    
      .swiper-pagination-bullet-active {
        background-color: #878787 !important;
      }
    }
  
    
  
    .arrow {
      position: absolute;
      width: 120px;
      top: 20px;
      right: 50px;
      transform: rotate(-45deg);
      transform-origin: top right;
    }
    
    .line {
      margin-top: 14px;
      width: 90px;
      background: #000;
      height: 10px;
      float: left;
    }
    
    .point {
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 30px solid #000;
      float: right;
    }
  }

  .fancybox-caption__body {
    font-family: 'Apercu-Regular', Helvetica, Arial, sans-serif;
    text-align: left;
    max-width: 900px !important;
    margin: auto;
  }

  .fancybox-is-open .fancybox-bg {
    background-color: #000 !important;
    opacity: 1 !important;
  }
}